import React  from 'react';
import cz from '../resources/cz.svg';
import sk from '../resources/sk.svg';
import history from "../common/history"
import { 
    css, 
} from "@bdfx/fukit"
import { getProblemUrl, getAuditUrl } from './helpers';
import {getAuditId, } from "../common/helpers"
import { getFilters, } from "./helpers"

const getTranslatedUrl = (requested, location) => {
    const locale = process.env.LANG
    const url = location.pathname
    const baseName = `https://bidding-audit.${requested}`
    if(locale === requested) {
      return baseName+url
    } else {
        if(url === "/podminky/") {
            return baseName+"/podmienky/"
        }
        if(url === "/podmienky/") {
            return baseName+"/podminky/"
        }
        if(url.indexOf('audit') > -1) {
            const problem = getFilters()
            if(problem) {
                return baseName+getProblemUrl(getAuditId(), problem, requested)
            }
            return baseName+getAuditUrl(getAuditId())
        }

      return baseName+url
    }
  }
  
const LanguageSwitcher = () => {
    const [czLink, setCzLink] = React.useState(null)
    const [skLink, setSkLink] = React.useState(null)
    React.useEffect(() => {
       return history.listen((location) => { 
           setCzLink(getTranslatedUrl('cz', location))
           setSkLink(getTranslatedUrl('sk', location))
       }) 
    },[]) 

    return <Style>
                <li><a href={czLink ?? getTranslatedUrl('cz', window.location)}><img width="25" height="18" src={cz} alt="Čeština" /></a></li>
                <li><a href={skLink ?? getTranslatedUrl('sk', window.location)}><img width="25" height="18" src={sk} alt="Slovenština" /></a></li>
            </Style>
}

const Style = css("ul")({
    marginTop: "-25px",
    marginRight: "8px",
    textAlign: "right",
    paddingLeft: "0.75em",
    "li": {
      display: "inline-block",
      "img": {
        maxWidth: "25px",
      },
      marginRight: "5px",
    }
  })


export default LanguageSwitcher