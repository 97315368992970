import React, {useState} from 'react';
import infografika from '../../resources/infografika-bf.svg';
import { 
  css, 
  modal,
  Grid,
} from "@bdfx/fukit"
import Form from "../../common/homepage/form"
import References from "./references"
import Showroom from "./showroom"
import ctrans from "../../common/trans"
import {eventGA} from "../../common/tracking"
import Wave from "./wave"

export function findGetParameter(parameterName) {
  var result = null ,tmp = [];
  window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
  return result ? decodeURIComponent(result) : "";
}

const Lazyload = ({ loader }) => {
  const Component = React.lazy(loader)
  return (props) => <React.Suspense fallback={() => <div/>} >
                      <Component {...props} />
                  </React.Suspense>
}

const Modal = Lazyload({
  loader: () => import(/* webpackChunkName: "modal" */ './modal'),
});

function Homepage() {
  const [feed_url, setFeedUrl]:[string, Function] = useState(findGetParameter('feed') ?? "")
  const [conversion_url, setConversionUrl]:[string, Function] = useState(findGetParameter('conversion') ?? "")
  const [element, setElement]:[string, Function] = useState(findGetParameter('element') ?? "")

  return (
    <>  
        <HeroSection>
            <HeroContent cols="1fr auto" gap="50px" valign="center">
              <HeroText>
                {ctrans("bidding_audit_hero_text")}
              </HeroText>
                <img width="660" height="270" src={infografika} alt="Infografika" />
            </HeroContent>
        </HeroSection>
        <Wave from="#d32c30" to={"#fff"} />
        <FormSection>
            <Form feed_url={feed_url} setFeedUrl={setFeedUrl} conversion_url={conversion_url} setConversionUrl={setConversionUrl}
                  element={element} setElement={setElement}
                  showModal={() => {
                    eventGA("AUDIT", "Open modal window", "OPEN_MODAL")

                    modal({closeButton: undefined, closeOnClickOutside: false, message: (close) => <Modal close={close} 
                                                                                            feed_url={feed_url}
                                                                                            conversion_url={conversion_url}
                                                                                            element={element}
                                                                                      />})
                    }
                  }
            />
        </FormSection>
        <Wave from="#fff" to={"#ffeced"} v={2} />
        <ReferencesSection>
            <References />
        </ReferencesSection>
        <Wave from="#ffeced" to={"#fff"} v={3} />
        <ShowroomSection>
            <Showroom />
        </ShowroomSection>
        <Wave from="#fff" to={"#d32c30"} v={4} />
    </>
  );
}

const HeroSection = css("div")({
  background: "#d32c30",
  "@media only screen and (max-width: 1000px)": {
    paddingBottom: "50px",
  },
})

const FormSection = css("div")({
  background: "#fff",
  padding: "0 1em",

  ".content": {
    paddingTop: 0,
    position: "relative",
    zIndex: 1,
  },

  "@media only screen and (max-width: 1000px)": {
    ".fuk-grid": {
      display: "block",
      textAlign: "center",
      " > div": {
        marginBottom: "4em",
      },
    },
    ".fuk-button": {
      marginTop: "-2em",
    },
  },


})


const ReferencesSection = css("div")({
  background: "#ffeced",
  paddingTop: "20px",
  paddingBottom: "100px",
  "@media only screen and (max-width: 1000px)": {
      "img": {
        width: "125px",
        height: "125px",
      },
      "p": {
        marginLeft: "-80px",
        fontSize: "1.2rem",
        padding: "1em",
      },
  },

})



const ShowroomSection = css("div")({
  background: "#fff",
  paddingBottom: "100px",
  "@media only screen and (max-width: 1000px)": {
    ".fuk-grid": {
      display: "block",
      "p": {
        textAlign: "center",
        fontSize: "1.2rem",
        padding: "1em",
      }
    },
  },

})


const HeroContent = css(Grid)({
  maxWidth: "1100px",
  margin: "auto",
  padding: "4em 1em 9em",
  position: "relative",
  zIndex: 1,

  "@media only screen and (max-width: 1000px)": {
    display: "block",
    textAlign: "center",
    padding: "3em 1em 5em",
    "img": {
      display: "none",
    }
  }

})
const HeroText = css("h1")({
  fontSize: "48px",
  color: "white",
  textAlign: "left",
  marginTop: "-20px",
  fontWeight: "normal",
  lineHeight: "1.2em",
  "strong": {
    fontSize: "52px",
  },
  "@media only screen and (max-width: 1000px)": {
    textAlign: "center",
  },

})

export default Homepage;
