import { createBrowserHistory } from "history"
import { getAuditId } from "./helpers"
import ctrans from "./trans"

export const history = createBrowserHistory({
    basename: "/",
});

const suffix = process.env.APP === "pricing" ? " | Pricing Audit" : " | Bidding Audit"

const setDocumentTitle = () => {
    const auditId = getAuditId()
    if(auditId) {
        if(auditId === "example") {
                document.title = ctrans("example_results") + suffix
        } else {
                document.title = ctrans("audit_result") + suffix
        }
        const description = document.querySelector('.meta-description')
        if(description) {
            description.setAttribute('content',ctrans("meta_description_result_"+process.env.APP))
        }
        const og_description = document.querySelectorAll('.og-description')
        if(og_description) {
            og_description[0]?.setAttribute('content',ctrans("og_description_result_"+process.env.APP))
            og_description[1]?.setAttribute('content',ctrans("og_description_result_"+process.env.APP))
            og_description[2]?.setAttribute('content',ctrans("og_description_result_"+process.env.APP))
        }
        const og_title = document.querySelectorAll('.og-title')
        if(og_title) {
            og_title[0]?.setAttribute('content',ctrans("og_title_result_"+process.env.APP))
            og_title[1]?.setAttribute('content',ctrans("og_title_result_"+process.env.APP))
            og_title[2]?.setAttribute('content',ctrans("og_title_result_"+process.env.APP))
        }
    } else {
        document.title = ctrans("page_title_"+process.env.APP)
    }
}

setDocumentTitle()

history.listen((location, action) => {
    console.debug(`The current URL is ${location.pathname}${location.search}${location.hash}`)
    console.debug(`The last navigation action was ${action}`)
    window.scrollTo(0,0)
    setDocumentTitle()
 
})


export default history
