import React from 'react';
import janka from '../../resources/jana-dzurickova.jpg';
import filip from '../../resources/filip-vasicek.jpg';
import marketa from '../../resources/marketa-gramesova.jpg';
import ondra from '../../resources/ondrej-vodehnal.jpg';
import ctrans from "../../common/trans"
import { 
  css, 
  Carousel,
  Grid,
  Header,
  Div,
} from "@bdfx/fukit"
function References() {
  return (
    <Style>
        <Header type={2}>{ctrans("references")}</Header>
        <Carousel count={4}>
            {(next, prev) => [
              <Div key="vodehnal" maxWidth="1000px" margin="auto">
                <Grid cols="200px auto" gap="40px" valign="center">
                  <Div>
                    <img width="200" height="200" src={ondra} alt="Ondřej Vodehnal" />
                  </Div>
                    <p>
                    Dostat produktové feedy do Heureky a Zboží je často nadlidský úkon. Nejasné chybové hlášky, změny pravidel atd. jsou pro mnoho eshoperů nepřekonatelnou překážkou. Ve feedu o tisících řádcích může občas i malá chyba způsobit vypnutí inzerce či razantní snížení její efektivity. Ale to pro naše klienty neplatí. Využívámím auditů od BF odhalujeme i sebemenší chybičky a díky radám u reportu chyb opravujeme "potížisty" rychle a, efektivně. Díky tomu naši klienti těží z Heureky či Zboží maximum.
                    <br/>
                    <br/>
                    Za team <a href="https://www.filipesmedia.cz/" target="_blank">FILIPES MEDIA s.r.o.</a> CTO Ondřej Vodehnal 
                  </p>
                </Grid>
              </Div>,

              <Div key="trevisan" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={janka} alt="Jana Dzuričková doporučuje" />
                    </Div>
                    <p>
                      Bidding Fox technologies, s.r.o je partia skvelých a pracovitých ľudí, na ktorých sa viete kedykoľvek spoľahnúť. Požiadavky a problémy vyriešia takmer vždy do 24 hodín od ich nahlásenia. Spolupracujeme spolu už niekoľko rokov a aplikácie z ich dielne dokážu nielen prekvapiť, ale aj reálne pomôcť rozvoju vášho podnikania. Teším sa na ďalšie roky našej spolupráce.
                      <br/>
                      <br/>
                      Jana Dzuričková / Unikum <a href="https://unikum.cz/" target="_blank">Unikum</a><br/>
                      Senior Inbound Specialist 
                    </p>
                  </Grid>
              </Div>,
              <Div key="vogl" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={marketa} alt="Markéta Gramesová doporučuje" />
                    </Div>
                    <p>
                    Vyzkoušeli jsme všechny dostupné nástroje pro automatický bidding a zjistili jsme, že Bidding Fox přináší nejlepší poměr cena/výkon. K jeho hlavním výhodám patří propojenost s Mergadem, jednoduchá obsluha, neustálý vývoj funkcí, skvělá podpora pro agentury a v neposlední řadě i příznivá cena.
                      <br/>
                      <br/>
                      Markéta Gramesová / <a href="https://www.advisio.cz/" target="_blank">Advisio</a>
                    </p>
                  </Grid>
              </Div>,
              <Div key="drazny" maxWidth="1000px" margin="auto">
                  <Grid cols="200px auto" gap="40px" valign="center">
                    <Div>
                      <img width="200" height="200" src={filip} alt="Filip Vašíček doporučuje" />
                    </Div>
                    <p>
                      Bidding Fox používám už léta, za která urazil neuvěřitelný kus cesty vpřed. Pro naprostou většinu našich klientů je tím nejvhodnějším biddovacím nástrojem, v poměru cena/výkon. Jeho napojení na Mergado mi navíc poskytuje spoustu výhod, ať už práci se stejnými skupinami produktů, (které mám pak v Bidding Foxu již předchystané) nebo podpora dalších aplikací jako párovací Pairing Bear, či Bidding Fox Elements. Za těch 12 nebo kolik stovek měsíčně si na sebe vydělá, ušetří klientovi peníze a zvýší jim prodej toho zboží, které je dobře konkurenceschopné.
                      <br/>
                      <br/>
                      Filip Vašíček, <a href="https://www.besteto.cz/" target="_blank">Besteto</a>
                    </p>
                  </Grid>
              </Div>,

          ]
            }
          </Carousel>
    </Style>
  );
}

const Style = css("div")({
    "@media only screen and (max-width: 1000px)": {
      ".fuk-grid": {
        display: "block",
        "img": {
          maxWidth: "150px",
        },
        "p": {
          padding: "5px !important",
          marginLeft: "0 !important",
        },

      },
    },
    maxWidth: "1200px",
    margin: "0 auto",
    paddingBottom: "100px",

    "img": {
      maxWidth: "200px",
      clipPath: "circle(99px at center)",

    },

    "p": {
      textAlign: "justify",
      fontSize: "1.4em",
      fontStyle: "italic",
    },


})

export default References;
