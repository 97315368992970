import React from 'react';
import {
  Button, 
  TextInput, 
  FieldLabel, 
  Icon, 
  css, 
  Grid,
  Div,
  Tooltip,
} from "@bdfx/fukit"
import ctrans from "../trans"

interface Props {
  feed_url: string
  setFeedUrl: Function
  conversion_url: string
  setConversionUrl: Function
  showModal: Function
  element: string
  setElement: Function
}

function Form(props: Props) {

  const { feed_url, setFeedUrl, conversion_url, setConversionUrl, element, setElement, showModal } = props
  return ( 
        <Content className="content">
            <FieldLabel htmlFor="conversion_url" bold={true}>{ctrans("conversion_url_label")}: <Tooltip content={ctrans("conversion_url_hint")}><Icon color="#ccc" size={20} type="hint-info" /></Tooltip></FieldLabel>
            <TextInput id="conversion_url" placeholder={`https://www.heureka.${process.env.LANG}/direct/xml-export/shops/roi/xxx/yyy.xml.gz`} value={conversion_url} onChange={setConversionUrl} />
            {/* {conversion_url.length > 0 ? 
              <> */}
              <Icon className="arrow" size={30} type="chevron-down-light" color="#ddd" />
              <Grid cols={process.env.APP === "pricing" ? "1fr 1fr" : "1fr"} gap="20px" className="optional">
                <Div>
                  <FieldLabel htmlFor="feed_url">{ctrans("feed_url_label")}: <Tooltip content={ctrans("feed_url_hint")}><Icon color="#ccc" size={20} type="hint-info" /></Tooltip></FieldLabel>
                  <TextInput placeholder="Nepovinné" id="feed_url" value={feed_url} onChange={setFeedUrl} />
                </Div>
                {process.env.APP === "pricing" ? 
                <Div>
                  <FieldLabel htmlFor="element">{ctrans("element_label")}: <Tooltip content={ctrans("element_hint")}><Icon color="#ccc" size={20} type="hint-info" /></Tooltip></FieldLabel>
                  <TextInput placeholder="Nepovinné" id="element" value={element} onChange={setElement} />
                </Div>
                : 
                null}
              </Grid>
              {/* </>
            : null } */}
            <Button className="action-button" color="green" size="big" onClick={showModal}>{ctrans("continue")}</Button>
        </Content>
  );
}

const Content = css("div")({
  maxWidth: "800px",
  margin: "auto",
  padding: "6em 0 4em",
  ".optional .fuk-field-label": {
    marginBottom: "0.5rem",
    fontSize: "1.2rem",
  },
  "#conversion_url": {
    fontSize: "20px",
    height: "50px",
    lineHeight: "50px",

  },
  ".action-button": {
    fontWeight: "bold",
  },
  ".fuk-tooltip": {
    float: "right",
  },
  ".muk-popuphint": {
    float: "right",
  },

  ".fuk-field-label": {
    fontSize: "1.4em",
    marginBottom: "1em",
  },

  ".arrow": {
    margin: "1.5em 0 2.5em",
  },

  ".fuk-button": {
    margin: "2em 0",
  },
})

export default Form;
