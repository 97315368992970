import {
    theme,
} from "@bdfx/fukit"

export const ERRORS = ["not_paired", "no_heureka_cpc", "zero_heureka_cpc",  "no_delivery_date", "single_shop", "ppc_off", "cpc_lower_than_min_cpc" ]
export const WARNINGS = ["clicks_without_orders","different_cpc_in_conversions", "bidding_enabled_without_me", "too_expensive_for_bidding",  "in_bidding_without_clicks", "cpc_50_percent_of_price",  ]
export const SUGGESTIONS = ["too_high_availability_for_bidding", "in_bidding_and_top_cart", "more_than_5_position", "first_and_too_cheap_wrong_paired", "top_product_not_in_bidding", "edgy_delivery", "high_pno" ]


export const getColor = (index) => {
    if(ERRORS.indexOf(index) > -1 || index === "bidding_group_problems") {
        return theme.red_raw
    }
    if(WARNINGS.indexOf(index) > -1 || index === "bidding_group_warnings") {
        return theme.orange_raw
    }
    if(SUGGESTIONS.indexOf(index) > -1 || index === "bidding_group_recommendations") {
        return theme.steelblue_raw
    }
    return theme.green_raw
}

export const getAuditUrl = (auditId) => {
    if(auditId === "example") {
        return `/ukazka_auditu/`
    }
    return `/audit/${auditId}/`
}

export const getProblemUrl = (auditId, index, requested = null) => {
    return  getAuditUrl(auditId) + trans_problem_to_url(index, requested) + "/"
}

export function getFilters() {
    const reg = window.location?.pathname && window.location.pathname.indexOf("ukazka_auditu") > -1 ?  /\/ukazka_auditu\/([0-9a-z_]+)/g : /\/audit\/[0-9a-z]+\/([0-9a-z_]+)/g
    const pid = reg.exec(window.location.pathname)
    if (pid) {
        return trans_url_to_problem(pid[1])
    }
    return ""
}


export const trans_problem_to_url = (index, requested = null) => {
    if(index === "regenerate") return "regenerate"
    const lang = (requested ? requested : process.env.LANG) === "sk" ? 1 : 0
    if(trans_problems?.[index]?.[lang]) {
    return trans_problems[index][lang]
    } else {
        console.log('MISSING PROBLEM', index, requested)
        return trans_problems[index][lang]
    }
}

export const trans_url_to_problem = (problem, requested = null) => {
    if(problem === "regenerate") return "regenerate"
    const lang = (requested ? requested : process.env.LANG) === "sk" ? 1 : 0
    const values = Object.keys(trans_problems).filter(index => trans_problems[index][lang] === problem)
    if(values && values.length > 0) {
        return values[0]
    }
    console.log('MISSING URL', problem, requested)
    return ''
}

const trans_problems = {
    "bidding_group_ok": [
        "v_poradku",
        "v_poriadku",
    ],
    "bidding_group_problems": [
        "s_problemem",
        "s_problemom",
    ],
    "bidding_group_warnings": [
        "s_upozornenim",
        "s_upozornenim",
    ],
    "bidding_group_recommendations": [
        "s_doporucenim",
        "s_odporucanim",
    ],
    "not_paired": [
        "nesparovane_produkty",
        "nesparovane_produkty",
    ],
    "no_heureka_cpc": [
        "bez_heureka_cpc",
        "bez_heureka_cpc",
    ],
    "zero_heureka_cpc": [
        "nulove_heureka_cpc",
        "nulove_heureka_cpc",
    ],
    "no_delivery_date": [
        "bez_dostupnosti",
        "bez_dostupnosti",
    ],
    "single_shop": [
        "sam_v_karte",
        "sam_v_karte",
    ],
    "ppc_off": [
        "nelze_biddovat",
        "nejde_biddovat",
    ],
    "few_shops": [
        "malo_eshopu",
        "malo_eshopov",
    ],
    "cpc_lower_than_min_cpc": [
        "cpc_mensi_nez_minimum_kategorie",
        "cpc_mensie_ako_minimum_kategorie",
    ],
    "clicks_without_orders": [
        "prokliky_bez_objednavek",
        "prekliky_bez_objednavok",
    ],
    "different_cpc_in_conversions": [
        "rozdil_v_cpc",
        "rozdiel_v_cpc",
    ],
    "bidding_enabled_without_me": [
        "nejsem_v_biddingu",
        "niesom_v_biddingu",
    ],
    "top_product_not_in_bidding": [
        "nebidduji_top_produkt",
        "nebiddujem_top_produkt",
    ],
    "too_expensive_for_bidding": [
        "moc_drahy",
        "prilis_drahy",
    ],
    "my_price_below_purchase_price": [
        "pod_nakupni_cenou",
        "pod_nakupnou_cenou",
    ],
    "in_bidding_without_clicks": [
        "v_biddingu_bez_prokliku",
        "v_biddingu_bez_prekliku",
    ],
    "cpc_50_percent_of_price": [
        "vysoke_cpc",
        "vysoke_cpc",
    ],
    "too_high_availability_for_bidding": [
        "dlouha_dostupnost",
        "dlha_dostupnost",
    ],
    "in_bidding_and_top_cart": [
        "v_biddingu_na_top_pozici",
        "v_biddingu_na_top_pozicii",
    ],
    "more_than_5_position": [
        "drazsi_produkty",
        "drahsie_produkty",
    ],

    "first_and_too_cheap_wrong_paired": [
        "prilis_levny_spatne_sparovany",
        "prilis_lacny_nespravne_sparovany",
    ],

    "edgy_delivery": [
        "zlomova_dostupnost",
        "zlomova_dostupnost",
    ],
    "high_pno": [
        "vysoke_pno",
        "vysoke_pno",
    ],
}