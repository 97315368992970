import React from 'react';
import results1 from "../../resources/b_slide1.jpg"
import results2 from "../../resources/b_slide2.jpg"
import results3 from "../../resources/b_slide3.jpg"
import results4 from "../../resources/b_slide4.jpg"
import results5 from "../../resources/b_slide5.jpg"
import ctrans from "../../common/trans"
import { 
    css, 
    Carousel,
    Grid,
    Header,
    GridCell,
} from "@bdfx/fukit"

function Showroom() {

  return (
    <Style>
        <Header type={2}>{ctrans("showroom_title")}</Header>
        <Carousel count={5}>
            {(next, prev) => [
                <div key="first" className="card">
                    <Grid cols={"auto 1fr"} valign="center" align="left" gap="0">
                            <img width="500" height="301" alt="Ukázka výsledku auditu" src={results1} />
                            <p>
                                {ctrans("bidding_showroom_slide_1")}
                            </p>
                    </Grid>
                </div>,
                <div key="second" className="card">
                    <Grid cols={"1fr auto"} valign="center" align="left" gap="0">
                        <GridCell col={2} row={1}>
                        <img width="500" height="301" alt="Ukázka výsledku auditu"  src={results2} />
                        </GridCell>
                        <p>
                            {ctrans("bidding_showroom_slide_2")}
                        </p>
                    </Grid>
              </div>,
              <div key="third" className="card">
                <Grid cols={"auto 1fr"} valign="center" align="left" gap="0">
                    <img width="500" height="301" alt="Ukázka výsledku auditu"  src={results3} />
                    <p>
                        {ctrans("bidding_showroom_slide_3")}
                    </p>
                </Grid>
              </div>,
              <div key="fourth" className="card">
                <Grid cols={"auto 1fr"} valign="center" align="left" gap="0">
                    <GridCell col={2} row={1}>
                        <img width="500" height="301" alt="Ukázka výsledku auditu"  src={results4} />
                    </GridCell>
                    <p>
                        {ctrans("bidding_showroom_slide_4")}
                    </p>
              </Grid>
            </div>,
              <div key="third" className="card">
              <Grid cols={"auto 1fr"} valign="center" align="left" gap="0">
                  <img width="500" height="301" alt="Ukázka výsledku auditu"  src={results5} />
                  <p>
                      {ctrans("bidding_showroom_slide_5")}
                  </p>
              </Grid>
            </div>,
          ]
            }
            </Carousel>
    </Style>
  );
}

const Style = css("div")({

    "@media only screen and (max-width: 1000px)": {
        ".fuk-grid": {
          display: "block",
          "img": {
            maxWidth: "80%",
            width: "auto",
            height: "auto",
          },
          "p": {
              padding: "5px !important",
          },
        },
      },
  
    position: "relative",
    zIndex: 1,
    margin: "0 auto",
    maxWidth: "1200px",
    marginTop: "-80px",
    paddingBottom: "100px",
    ".card": {
        padding: "20px",
        maxWidth: "1100px",
        margin: "auto",
    },
    "p": {
        textAlign: "left", 
        fontSize: "1.4em",
    },
})


export default Showroom;
