import * as React from "react"
import {
    css,
} from "@bdfx/fukit"

const Wave = ({from, to, v = 1, className = ''}) => {
    const Version = v === 4 ? V4 : v === 3 ? V3 : v === 2 ? V2 : V1
    return <Style className={`wave-divider ${className}`} from={from} to={to}>
                <Version className="version" from={from} to={to}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </Version>
        </Style>
}

const Style = css("div")({
    position: "relative",
    ".version": {
        position: "absolute",
        bottom: "0",
        left: "0",
        overflow: "hidden",
        lineHeight: "0",
    },
    "svg": {
        position: "relative",
        display: "block",
    },

    "@media only screen and (max-width: 1000px)": {
        "svg": { maxHeight: "80px", width: "150%", },

    },
}, props => ({
    ".version": {
        background: props.from,
    },
    "path": {
        fill: props.to,
    },
}))

const V1 = css("div")({
    width: "100%",

    "svg": {
        width: "100%",
        height: "150px",
    },

})

const V2 = css("div")({
    width: "120%",

    "svg": {
        width: "120%",
        height: "100px",
        transform: "rotate(180deg) translate3d(20%,0,0)",
    },
}, props => ({
    background: props.to + "!important",
    "path": { 
        fill: props.from + "!important",
    },
}))

const V3 = css("div")({
    width: "100%",
  "svg": {
    width: "160%",
    height: "200px",
    transform: "translate3d(-15%,0,0)",

  },

})

const V4 = css("div")({
    width: "100%",
    
    "svg": {
        width: "120%",
        height: "150px",
        transform: "scale(-1, 1) translate(150px,0)",
    },
})

export default Wave
