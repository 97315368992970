import React from 'react';
import { hydrate, render } from "react-dom";
import './style.css';
import '../common/style.css';
import App from './app';
// import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(<App lang={process.env.LANG} key={window.location.pathname} />, rootElement);
} else {
  render(<App lang={process.env.LANG} />, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
