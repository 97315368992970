

export function getAuditId() {
    if(window.location?.pathname && window.location.pathname.indexOf("ukazka_auditu") > -1) {
        return "example"
    }

    const pid = /\/audit\/([0-9a-z]+)/g.exec(window.location.pathname)
    if (pid) {
        return pid[1]
    }
    return ""
}

